import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { useAPI } from 'utils/api';
import { COUPON_STATUS } from 'utils/constants/couponStatus.js';
import { callAppFunc } from 'utils/jsbridge';

import IconArrow from 'images/icons/icon-blue-arrow.svg';
import { setInsiderCustomEvent } from 'utils/_insider';

const Card = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #fffde9;
  display: flex;
  overflow: hidden;

  ${(props) =>
    props.isDone &&
    css`
      ${CardStatus} {
        background-color: #5fd2da;
      }

      ${DescriptionBtn} {
        background-color: #6fdee5;
      }
    `}

  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

const CardDetail = styled.div`
  flex: 1;
  padding: 8px 16px;
`;

const CardTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #fd5151;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  > span {
    font-size: 12px;
    margin-left: 5px;
  }
`;

const CardSubtitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #333;
`;

const CardDescription = styled.div`
  margin-top: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #666;
`;

const CardStatus = styled.div`
  flex: 0 0 80px;
  border-left: 3px dashed #fffde9;
  background-color: #ff7a7a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CardStatusText = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #fff;
`;
const DescriptionWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  cursor: pointer;
  > div {
    font-size: 12px;
  }
  > img {
    margin-left: 5px;
    width: 12px;
  }
`;
const DescriptionBtn = styled.button`
  width: 64px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: none;
  background-color: #ff8e8e;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-top: 10px;
  padding: 0;
`;

const CouponCard = ({
  coupon,
  showTicketDetail,
  status = COUPON_STATUS.NONE,
  isFromMyCoupon = false,
}) => {
  const api = useAPI();
  const [isReceive, setIsReceive] = useState(true);
  const [isDisable, setIsDisable] = useState(false);
  const [isIssuedAll, setIsIssuedAll] = useState(false);

  const receiveCoupon = (couponId) => {
    if (isReceive) return;

    api
      .receiveCoupon({ couponId })
      .then((res) => {
        const { isOwner, isIssuedAll } = res;
        setIsReceive(isOwner);
        setIsIssuedAll(isIssuedAll);
        if (isIssuedAll) {
          setIsDisable(true);
        }
        setInsiderCustomEvent('coupon_info', {
          coupon_name: coupon?.couponName,
          coupon_expiration_date: coupon?.couponEndDate,
        });
        callAppFunc('showAlertView', {
          msg: res.message,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setTicketStatus = (ticketStatus) => {
    switch (ticketStatus) {
      case COUPON_STATUS.CAN_USE:
        setIsDisable(false);
        break;
      case COUPON_STATUS.CAN_NOT_USE:
        setIsDisable(true);
        break;
      case COUPON_STATUS.NOT_ACTIVE:
        setIsDisable(true);
        break;
    }
  };

  useEffect(() => {
    if (coupon?.isOwner) {
      setIsReceive(true);
    } else {
      setIsReceive(false);
    }
  }, []);

  useEffect(() => {
    if (status !== COUPON_STATUS.NONE) {
      setIsReceive(true);
      setTicketStatus(status);
    }

    if (coupon?.isIssuedAll && !isFromMyCoupon && !coupon?.isOwner) {
      setIsIssuedAll(true);
      setIsReceive(false);
      setIsDisable(true);
    }
  }, [status]);

  return (
    <Card isDone={isReceive} isDisabled={isDisable}>
      <CardDetail>
        <CardTitle>
          {coupon?.couponName || ''}
          <span>折價券</span>
        </CardTitle>
        <CardSubtitle>{coupon?.couponTitle}</CardSubtitle>
        <DescriptionWrapper
          onClick={() => showTicketDetail(coupon?.couponDescription)}
        >
          <div>詳細說明</div>
          <img src={IconArrow} />
        </DescriptionWrapper>
        <CardDescription>
          <div>
            {isFromMyCoupon && status !== COUPON_STATUS.NOT_ACTIVE
              ? `使用期限：${coupon?.couponStartDate} ～ ${coupon?.couponEndDate}`
              : coupon?.dateDesc}
          </div>
        </CardDescription>
      </CardDetail>

      <CardStatus
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          receiveCoupon(coupon?.id);
        }}
      >
        {isIssuedAll && !isFromMyCoupon ? (
          <CardStatusText>已領完</CardStatusText>
        ) : (
          <CardStatusText>{coupon?.isOwner ? '已領' : '領券'}</CardStatusText>
        )}
      </CardStatus>
    </Card>
  );
};

export default CouponCard;
