import { callAppFunc } from './jsbridge';

const getEffectivePrice = (element) => {
  return element.price === element.salePrice
    ? element.price
    : element.salePrice;
};

const setInsiderLogEvent = (title, param) => {
  console.log('title', title);
  console.log('param', param);

  if (typeof window !== 'undefined') {
    //app 版本
    callAppFunc('appInsiderLogEvent', { title_name: title, parameters: param });
  }
};

const setInsiderVisitProductDetailPage = (element) => {
  console.log('setInsiderVisitProductDetailPage', JSON.stringify(element));
  let product = {
    product_id: element.id,
    product_name: element.name,
    taxonomy: [element.main_category, element.sub_category.name],
    image_url: element.imageGroups[0].images[0].link,
    price: getEffectivePrice(element),
    currency: 'TWD',
  };
  setInsiderLogEvent('insiderVisitProductDetailPage', product);
};

const setInsiderItemAddedToCart = (element) => {
  console.log('setInsiderItemAddedToCart', JSON.stringify(element));
  let product = {
    product_id: element.productId,
    product_name: element.itemName,
    taxonomy: [element.main_category],
    image_url: element.image,
    price: element.price,
    quantity: element.qty,
    currency: 'TWD',
  };
  setInsiderLogEvent('insiderItemAddedToCart', product);
};

const setInsiderItemRemovedFromCart = (pid) => {
  let product = {
    product_id: pid,
  };
  setInsiderLogEvent('insiderItemRemovedFromCart', product);
};

const setInsiderCartClear = () => {
  setInsiderLogEvent('insiderCartClear', {});
};

const setInsiderConfirmationPageView = (elements, transNo) => {
  elements.products.forEach((element) => {
    let categories = [];
    if (element?.main_category) {
      categories.push(element.main_category);
    }
    if (element?.category_name) {
      categories.push(element.category_name);
    }
    let product = {
      transaction_id: transNo,
      product_id: element.productnumber,
      product_name: element.item_name,
      taxonomy: categories,
      image_url: element.product_image_url,
      price: element.price,
      quantity: element.qty,
      currency: 'TWD',
    };
    setInsiderLogEvent('insiderConfirmationPageView', product);
  });
};

const setInsiderListingPageView = (categories) => {
  let product = {
    taxonomy: categories,
  };
  setInsiderLogEvent('insiderListingPageView', product);
};

const setInsiderCartPageView = (elements) => {
  let products = [];
  elements.forEach((product) => {
    products.push({
      product_id: product.productId,
      product_name: product.itemName,
      taxonomy: [product.main_category],
      image_url: product.image,
      price: product.price,
      quantity: product.qty,
      currency: 'TWD',
    });
  });
  setInsiderLogEvent('insiderCartPageView', { products: products });
};

const setInsiderCustomEvent = (title, param) => {
  let data = {
    eventName: title,
    ...param,
  };
  setInsiderLogEvent('insiderCustomEvent', data);
};

const setInsiderRedeemPointAmount = () => {
  setInsiderLogEvent('insiderRedeemPointAmount', {});
};

const setInsiderLastPurchaseDate = () => {
  setInsiderLogEvent('insiderLastPurchaseDate', {});
};

const setInsiderLastPurchaseCategory = (cateName) => {
  console.log('setInsiderLastPurchaseCategory', cateName);
  setInsiderLogEvent('insiderLastPurchaseCategory', {
    category_name: cateName,
  });
};

export {
  setInsiderLogEvent,
  setInsiderVisitProductDetailPage,
  setInsiderItemAddedToCart,
  setInsiderItemRemovedFromCart,
  setInsiderCartClear,
  setInsiderConfirmationPageView,
  setInsiderListingPageView,
  setInsiderCartPageView,
  setInsiderCustomEvent,
  setInsiderLastPurchaseDate,
  setInsiderRedeemPointAmount,
  setInsiderLastPurchaseCategory,
};
